html {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(11, 15, 93, 1);
  overflow: auto;
}

@media screen and (max-width: 2570px) {
  #body {
    transform: scale(1.33, 1.23);
    margin: 120px 0 0 0;
  }
}


// 显示器
// @media screen and (max-width: 1930px) {
//   #body {
//     transform: scale(1, 0.92);
//     margin: -40px 0 0 0;
//   }
// }

// windows
@media screen and (max-width: 1930px) {
  #body {
    transform: scale(0.9999, 0.88593);
    margin: -56px 0 0 0;
  }
}

// macbook pro 15
// @media screen and (max-width: 1930px) {
//   #body {
//     transform: scale(0.99, 1.03);
//     margin: 20px 0 0 0;
//   }
// }

@media screen and (max-width: 1690px) {
  #body {
    transform: scale(0.875, 0.83333);
    margin: -90px 0 0 0;
  }
}

@media screen and (max-width: 1450px) {
  #body {
    transform: scale(0.748666, 0.75741);
    margin: -130px 0 0 0;
  }
}

@media screen and (max-width: 1290px) {
  #body {
    transform: scale(0.666666, 0.65741);
    margin: -180px 0 0 0;
  }
}




body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1920px !important;
  height: 1080px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
