@import "../../assets/common.scss";
[data-component="MapControl"].container {
  z-index: 1;
  position: absolute;
  width: 1920px;
  height: 1080px;
  top: 0;
  left: 0;
  pointer-events: none;
  .layerContainer {
    width: 100%;
    height: 100%;
    // position: relative;
    pointer-events: none;
  }
  .topLayer {
    pointer-events: auto;
    width: 1920px;
    height: 216px;
    left: 0;
    top: 0;
    position: absolute;
  }
  .leftLayer {
    pointer-events: auto;
    width: 476px;
    height: 1080px;
    left: 0;
    top: 0;
    position: absolute;
  }
  .rightLayer {
    z-index: 2;
    pointer-events: auto;
    width: 530px;
    height: 1080px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .bottomLayer {
    pointer-events: auto;
    width: 1920px;
    height: 200px;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .controlContainer {
    z-index: 3;
    width: 400px;
    height: 260px;
    position: absolute;
    right: 64px;
    bottom: 80px;
    .controlButtonContainer {
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      .buttonSize {
        pointer-events: auto;
        z-index: 3;
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .dButton {
        margin-top: 6px;
        background-image: url("../../../public/img/2dControl.png");
      }
      .resetButton {
        margin-top: 6px;
        background-image: url("../../../public/img/resetControl.png");
      }
      .zoomOutButton {
        margin-top: 6px;
        background-image: url("../../../public/img/zoomInControl.png");
      }
      .zoomInButton {
        background-image: url("../../../public/img/zoomOutControl.png");
      }
    }
    .controllegend {
      z-index: 2;
      width: 400px;
      height: 80px;
      position: absolute;
      background-image: url("../../../public/img/legend.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      left: 0;
      bottom: 0;
    }
  }
  .hotpointsLegend {
    top: 800px;
    pointer-events: none;
    position: absolute;
    right: 480px;
    width: 250px;
    height: 140px;
    background-image: url('../../../public/img/hotpointsLegend.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
