@import "../../assets/common.scss";

$arrImage-height: 28px;
$label-height: 36px;
[data-component="ResponseServiceCard"] {
  display: inline-block;
  .container {
    z-index: 10;
    position: absolute;
    background-position: center;
    background-size: cover;
    .swiper-container {
      overflow-x: hidden;
      overflow-y: visible;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    .label {
      display: inline-block;
      width: 230px;
      height: $label-height;
      font-size: 24px;
      margin: 0 0 8px 0;
      font-family: PingFangSC-Semibold-GBpc-EUC-H;
      line-height: $label-height;
      text-align: center;
      color: #ffffff;
      text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.75);
    }
    .labelPosition {
      z-index: 6;
      position: absolute;
      top: 28px;
    }
    .arrImage {
      width: 407px;
      height: $arrImage-height;
    }
    .carouselContainer {
      width: 100%;
      padding-top: 22px;
      height: calc(100% - #{$arrImage-height});
      background-position: top;
      background-size: contain;
      background-repeat: no-repeat;
      overflow-y: visible;
    }
    .contentContainer {
      @include flex(column, nowrap, flex-start, center);
      width: 330px;
    }
    .contentContainer :last-child {
      background-image: none !important;
    }
    .contentItem {
      margin-top: 16px;
      padding-bottom: 16px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      @include flex(row, nowrap, space-between, center);
      background-position: bottom;
      background-size: 100% 4px;
      background-repeat: no-repeat;
      .itemLabel {
        font-size: 16px;
        width: 110px;
        text-align: left;
        font-family: PingFangSC-Regular-83pv-RKSJ-H;
        color: #ffffff;
      }
      .itemValue {
        width: 210px;
        text-align: right;
        font-size: 18px;
        font-family: PingFangSC-Semibold-GBpc-EUC-H;
      }
    }
    .decryptButton {
      width: 100px;
      height: 150px;
      position: absolute;
      bottom: 60px;
      left: 250px;
      cursor:pointer;
      background-image: url(../../../public/img/decryptButton.png);
      background-position: center;
      background-size: cover;
    }
  }
}

.bulletCustom {
  color: #fff
}
