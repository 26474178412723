@import "../../assets/common.scss";

[data-component="HotPoints"].container {
  width: 1920px;
  height: 1080px;
  background-image: url(../../../public/img/HotPointsBG.png);
  background-position: center;
  background-size: cover;
  position: relative;
  .cooperatorLogos {
    z-index: 10;
    position: absolute;
    bottom: 30px;
    left: 670px;
    width: 590px;
    height: 34px;
  }
  .earthImg {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1920px;
    height: 380px;
    background-image: url('../../../public/img/earth.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .map {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .flagSize {
    width: 50px;
    height: 50px;
  }

  .popupContainer {
    pointer-events: none;
    width: 250px;
    height: 170px;
    background-position: center;
    background-size: cover;
    padding: 15px;
  }
  .l7-popup-content {
    background-color: transparent !important;
    pointer-events: none;
  }

  .popupTitle {
    font-size: 20px;
    font-family: PingFangSC-Semibold-GBpc-EUC-H;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    background-size: 150px 1px;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 10px 20px 5px;
  }

  .popupTextContainer {
    padding: 0 10px;
    @include flex(row, nowrap, space-between, center);
    margin: 10px 0;
  }

  .popupLabel {
    font-size: 17px;
    font-family: PingFangSC-Regular-83pv-RKSJ-H;
    line-height: 20px;
    color: #ffffff;
  }

  .popupText {
    font-size: 17px;
    font-family: PingFangSC-Semibold-GBpc-EUC-H;
    line-height: 20px;
  }
}

