@import "../../assets/common.scss";
[data-component="DataStore"].container {
  z-index: 10;
  position: absolute;
  width: 275px;
  height: 550px;
  top: 250px;
  right: 120px;
  .titleContainer {
    width: 255px;
    height: 40px;
    background: url("../../../public/img/titleCard.png");
    background-position: center;
    background-size: contain;
    font-size: 24px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }
  .dataStoreImg {
    width: 255px;
    position: absolute;
    bottom: 0;
  }
}
