@import "../../assets/common.scss";

[data-component="Navigation"] {
  position: absolute;
  width: 100%;
  height: 80px;
  z-index: 10;
  @include flex(row, nowrap, center, center);
  .container {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../../public/img/navigation.png");
    background-position: center;
    background-size: cover;
    @include flex(row, nowrap, space-between, flex-start);
    .contentContainer {
      width: 400px;
      height: 70%;
      @include flex(row, nowrap, flex-start, flex-end);
    }
    .backButton {
      width: 46px;
      height: 46px;
      cursor: pointer;
      margin-left: 63px;
    }
    .logoIcon {
      margin-left: 22px;
      width: 72px;
      height: 34px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .logoTitle {
      width: 180px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium-GBpc-EUC-H;
      line-height: 22px;
      color: #ffffff;
      margin: 4px 10px;
    }
    .titleContainer {
      width: 600px;
      height: 70%;
      margin-left: -130px;
      margin-top: 10px;
      @include flex(row, nowrap, center, center);
      .titleIcon {
        width: 46px;
      }
      .title {
        margin: 14px;
        color: #fff;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 300;
      }
    }
    .userTitle {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-family: PingFangSC-Medium-GBpc-EUC-H;
      @include flex(row, nowrap, center, center);
      color: #ffffff;
      margin: 10px 63px 0 0;
    }
  }
}
