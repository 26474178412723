@mixin flex($dir, $wrap: nowrap, $jc, $ai) {
  display: flex;
  flex-flow: $dir $wrap;
  justify-content: $jc;
  align-items: $ai;
}

.noWrap {
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.l7-popup-tip {
  background-color: transparent;
}
.l7-popup-content {
  background-color: transparent;
  padding: 0;
}
.l7-popup-tip {
  border-top-color: rgba(0, 0, 0, 0.4) !important;
}
