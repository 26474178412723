@import "../../assets/common.scss";

[data-component="Track"].container {
  width: 1920px;
  height: 1080px;
  background-image: url(../../../public/img/HotPointsBG.png);
  background-position: center;
  background-size: cover;
  position: relative;
  .cooperatorLogos {
    z-index: 5;
    position: absolute;
    bottom: 30px;
    left: 670px;
    width: 590px;
    height: 34px;
  }
  .earthImg {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1920px;
    height: 380px;
    background-image: url('../../../public/img/earth.png');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .map {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  .pointSize {
    // width: 220px;
    height: 112px;
    display: flex !important;
    @include flex(row, nowrap, flex-start, center);
  }
  .bottomCircleSize {
    width: 36px;
    height: 15px;
    background-color: #FF681E;
    border-radius: 50% / 50%;
    position: absolute;
    bottom: 10px;
    left: -8px;
    z-index: 0;
  }
  .flagSize {
    width: 20px;
    height: 80px;
    z-index: 1;
  }
  .msgContainer {
    margin-left: 10px;
    width: 200px;
    z-index: 1;
    min-height: 110px;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .msgTitle {
    font-size: 20px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #ffffff;
    line-height: 29px;
    margin: 8px;
    text-align: center;
  }
  .msgText {
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #ffffff;
    line-height: 27px;
    margin: 20px 8px;
    text-align: center;
  }
  .popupLayer {
    pointer-events: none;
    position: absolute;
    left: -5000px;
    top: -5000px;
    width: 9999px;
    height: 9999px;
    z-index: 0;
    background-color: rgba(#000000, 0.6);
  }
  .popupContainer {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 400px;
    height: 250px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px 46px 20px 100px;
  }
  .l7-popup-content {
    width: 400px;
    height: 250px;
    background-color: transparent !important;
    // pointer-events: none;
  }
  .popupTitle {
    pointer-events: auto;
    font-size: 22px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0 20px 24px;
  }
  .popupTextContainer {
    height: 20px;
    margin: 10px;
    @include flex(row, nowrap, space-between, center);
  }
  .popupLabel {
    font-size: 16px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: rgba(220, 220, 220, 0.8);
  }
  .popupText {
    font-size: 16px;
    pointer-events: auto;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
  }
}
