@import "../../assets/common.scss";

[data-component="TrackEnter"] {
  .container {
    .loadContainer {
        top: 200px;
        left: 650px;
        z-index: 10;
        position: absolute;
        width: 686px;
        height: 800px;
        .loadOuterImg {
            position: absolute;
            height: 686px;
        }
        .loadOuterAnimateImg {
          position: absolute;
          left: -200px;
          top: -150px;
          width: 1090px;
          // height: 800px;
      }
        .loadBottomImg {
            position: absolute;
            top: 600px;
            width: 686px;
        }
        .giphyImg {
            position: absolute;
            top: 100px;
            left: 100px;
            width: 500px;
            height: 500px;
            opacity: 0.65;
        }
    }
  }
}
