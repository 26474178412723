[data-component="ShadeLayer"] {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  .container {
    width: 1920px;
    height: 1080px;
    pointer-events: none;
    position: relative;
    background: linear-gradient(180deg, #1b1b1f 0%, #0b0b0d 100%);
    opacity: 0.74;
    z-index: 5;
  }
  .containerMap {
    width: 1920px;
    height: 1080px;
    top: 0;
    pointer-events: none;
    position: relative;
    background-image: url('../../../public/img/containerMap.png');
    // background: radial-gradient( rgba(204, 206, 255, 0.2) 10%, rgba(204, 206, 255, 0.1) 40%, rgba(2, 30, 132, 0.6) 70%);
    opacity: 0.85;
    background-position: center;
    background-size: contain;
    z-index: 0;
  }
  .containerDecrypt {
    width: 1920px;
    height: 1080px;
    pointer-events: none;
    position: relative;
    background: radial-gradient( rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.6) 70%);
    opacity: 0.95;
    background-position: center;
    background-size: contain;
    z-index: 11;
    .decryptImg {
      position: absolute;
      left: 480px;
      top: 250px;
      width: 950px;
      height: 550px;
    }
  }
}
