@import "../../assets/common.scss";

$text-theme-color: #00ffff;
$text-color: #ffffff;
$font-size: 18px;
$label-height: 29px;
[data-component="ApplyServiceCard"] {
  .container {
    z-index: 10;
    position: absolute;
    width: 408px;
    height: 413px;
    background-position: center;
    background-size: cover;
    .label {
      display: inline-block;
      width: 408px;
      text-align: center;
      margin: 8px 0;
      height: $label-height;
      font-size: 24px;
      font-family: PingFangSC-Semibold-GBpc-EUC-H;
      line-height: 29px;
      color: #ffffff;
      text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.75);
      opacity: 1;
    }
    .contentContainer {
      width: 100%;
      height: calc(100% - #{$label-height});
      position: relative;

      .countContainer {
        position: absolute;
        left: 40px;
        top: 5px;
        width: 120px;
        height: 100px;
        @include flex(column, nowrap, flex-start, center);
        .value {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-weight: bold;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-theme-color;
        }
        .label {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-color;
        }
      }

      .methodsContainer {
        position: absolute;
        right: 60px;
        top: 5px;
        width: 120px;
        height: 100px;
        @include flex(column, nowrap, flex-start, center);
        .value {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-weight: bold;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-theme-color;
        }
        .label {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-color;
        }
      }

      .blockServiceImg {
        position: absolute;
        width: 116px;
        height: 105px;
        top: 125px;
        left: 50px;
      }

      .blockServiceContainer {
        position: absolute;
        right: 70px;
        top: 155px;
        width: 120px;
        height: 100px;
        @include flex(column, nowrap, flex-start, center);
        .value {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-weight: bold;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-theme-color;
        }
        .label {
          margin: 0;
          line-height: 24px;
          height: 24px;
          max-width: 150px;
          font-size: $font-size;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-color;
        }
      }

      .serviceContainer {
        position: absolute;

        top: 235px;
        width: 130px;
        height: 130px;
        background-image: url("../../../public/img/service.png");
        background-position: center;
        background-size: cover;
        @include flex(column, nowrap, flex-start, center);
        .value {
          margin: 8px 0 10px 0;
          line-height: 24px;
          height: 24px;
          max-width: 120px;
          font-size: $font-size;
          font-weight: bold;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-theme-color;
        }
        .label {
          margin: 40px 0 0;
          line-height: 24px;
          height: 24px;
          max-width: 120px;
          font-size: $font-size;
          font-family: PingFangSC-Semibold-GBpc-EUC-H;
          color: $text-color;
        }
      }
    }
  }
}
