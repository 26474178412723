@import "../../assets/common.scss";

$resetButton-height: 36px;

[data-component="TrackCard"] {
  .container {
    top: 200px;
    left: 64px;
    z-index: 10;
    position: absolute;
    width: 412px;
    height: 700px;
    @include flex(column, nowrap, flex-start, center);

    .titleContainer {
      width: 62%;
      height: 40px;
      margin-right: 50px;
      margin-bottom: 60px;
      background: url("../../../public/img/titleCard.png");
      background-position: center;
      background-size: contain;
      font-size: 24px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }

    .contentContainer {
      width: 100%;
      height: 600px;
      position: relative;
      background-position: center;
      background-size: cover;
      .itemTitleContainer {
        width: 300px;
        height: 40px;
        margin-top: 52px;
        margin-left: 40px;
        @include flex(row, nowrap, flex-start, center);
      }
      .itemCheckbox {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border: 1px solid;
        border-image: linear-gradient(
            44deg,
            rgba(5, 9, 108, 0),
            rgba(58, 139, 222, 0.78),
            rgba(73, 175, 254, 1),
            rgba(58, 140, 223, 0.79),
            rgba(5, 9, 108, 0)
          )
          1 1;
        .ant-checkbox {
          top: 0;
          .ant-checkbox-inner {
            width: 34px;
            height: 34px;
            background-color: transparent;
            border: 0;
            &::after {
              top: 15px;
              left: 10px;
              width: 10px;
              height: 20px;
              border-color: #00DCFF;
            }
          }
        }
        .ant-checkbox-checked::after {
          border: 0;
        }
      }
      .itemTitle {
        margin: 0;
        padding: 0 10px;
        width: 215px;
        display: inline-block;
        font-size: 24px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        // text-align: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .resetButton {
        position: absolute;
        bottom: 63px;
        left: 124px;
        width: 121px;
        font-size: 20px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        cursor: pointer;
        // color: #ffffff;
        height: $resetButton-height;
        line-height: $resetButton-height;
        text-align: center;
        // background: url("../../../public/img/resetButton.png");
        background-position: center;
        background-size: cover;
      }
    }
  }
}
